/* eslint-disable react-hooks/exhaustive-deps */
'use client';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Progress,
  Spinner,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import useAuth from 'hooks/auth';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { v4 as uuidv4 } from 'uuid';
import complianceData from './complianceData.json';
import { CloseIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { FaFileAlt, FaFilePdf, FaFileWord } from 'react-icons/fa';
import SEOComponent from 'seo';
import './MarketingReview.css';
import ResetButton from '../../../../components/resetButton/resetButton';
import useDisplayToast from '../../../../utils/DisplayToast';

export default function MarketingReview() {
  const [body, setBody] = useState<string>('');
  const [selectedCompliance, setSelectedCompliance] = useState<string[]>([]);
  const [complianceList, setComplianceList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [assistantResponse, setAssistantResponse] = useState<any>(null);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [wsLoading, setWsLoading] = useState<boolean>(false);
  const inputColor = useColorModeValue('navy.700', 'white');
  const textColor = useColorModeValue('navy.700', 'white');
  const placeholderColor = useColorModeValue(
    { color: 'gray.500' },
    { color: 'whiteAlpha.600' }
  );
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const { login } = useAuth();
  const { ensureValidToken } = useAuth();
  const [sessionId, setSessionId] = useState(null);
  const [sendButtonClicked, setSendButtonClicked] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [progressArray, setProgressArray] = useState<number[]>(
    Array(selectedFiles.length).fill(0)
  );
  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);
  const displayToast = useDisplayToast();

  useEffect(() => {
    if (!profile?.idToken || !profile?.user_id) {
      login();
      return;
    }
    setComplianceList(complianceData);
  }, []);

  function removeEndMarker(input: string): string {
    const endMarker = '<END>';
    if (input.endsWith(endMarker)) {
      setSendButtonClicked(false);
      return input.substring(0, input.length - endMarker.length);
    }
    return input;
  }

  const handleReset = () => {
    setBody('');
    setSelectedFiles([]);
  }

  const handleSendWSMessage = () => {
    if (!ws) return;
    let intervalId: number | null = null;
    const bufferArray: string[] = [];

    ws.onmessage = (event) => {
      setLoading(false);
      const message = event.data;
      let cleanText = message;

      if (message.endsWith('<END>')) {
        startNewSession();
        cleanText = removeEndMarker(cleanText);
        setLoading(false);
        setWsLoading(false);
      }

      bufferArray.push(cleanText);

      if (intervalId === null) {
        intervalId = window.setInterval(() => {
          if (bufferArray.length > 0) {
            const textToRender = bufferArray.shift();
            setAssistantResponse(textToRender);
          } else {
            clearInterval(intervalId);
            intervalId = null;
          }
        }, 33); // Adjust the interval duration as needed
      }
    };
  };

  const handleReviewMarketing = async () => {
    if (!ws) return;
    if (!profile?.idToken || !profile?.user_id) {
      login();
      return;
    }

    if (!body && selectedFiles.length === 0) {
      displayToast('Input Required','Please provide a body or attach a file to proceed.','info',3000);
       return;
    }

    setAssistantResponse(null);
    setSendButtonClicked(true);
    let fileUpload = false;
    if (selectedFiles.length > 0) {
      setWsLoading(true);
      fileUpload = true;
      await handleSendFiles();
    }

    if (
      ws.readyState === WebSocket.CLOSING ||
      ws.readyState === WebSocket.CLOSED
    ) {
      console.warn(
        'WebSocket is closing or closed. Attempting to reconnect...'
      );
      displayToast('WebSocket Disconnected','Reconnecting...','warning',3000);
      connectWebSocket();
      return;
    }

    setAssistantResponse(null);
    setLoading(true);
    setWsLoading(true);

    const selectedComplianceDetails = selectedCompliance.map((title) => {
      const compliance = complianceList.find((item) => item.title === title);
      return {
        title: compliance.title,
        descriptions: compliance.descriptions,
      };
    });
    const token = await ensureValidToken(null);

    const requestBody = {
      is_from_user_document: fileUpload,
      partner_id: process.env.REACT_APP_PARTNER_ID,
      partner: process.env.REACT_APP_PARTNER,
      persona: process.env.REACT_APP_MARKETING_REVIEW_PERSONA,
      UUId: uuidv4(),
      question: body,
      checklist_array: selectedComplianceDetails,
      user_id: profile?.user_id,
      session_id: sessionId,
      access_token: token,
      org_id: profile?.org_id
    };

    console.info('Calling API with:', requestBody);
    ws.send(JSON.stringify(requestBody));
    handleSendWSMessage();
  };

  const handleBodyChange = (event: any) => setBody(event.target.value);
  const handleComplianceChange = (value: string[]) =>
    setSelectedCompliance(value);

  const handleSelectAll = () =>
    setSelectedCompliance(complianceList.map((item) => item.title));
  const handleRemoveAll = () => setSelectedCompliance([]);

  const connectWebSocket = () => {
    const newWs = new WebSocket(
      `${process.env.REACT_APP_LLM_WS_URL}/ws-get-marketing-review`
    );
    newWs.onopen = () => {
      console.info('WebSocket connected');
      setWs(newWs);
    };
    newWs.onclose = () => {
      console.info('WebSocket disconnected');
    };

    newWs.onerror = (error) => {
      console.error('WebSocket error:', error);
      displayToast('Error connecting websocket','Failed to connect websocket. Please try again later.','error',3000);
    };

    return () => {
      if (ws) {
        ws.close();
      }
    };
  };

  useEffect(() => {
    if (!ws) {
      connectWebSocket();
    }

    const handleVisibilityChange = () => {
      console.info('document.visibilityState  ============');
      console.info(document.visibilityState);

      if (document.visibilityState === 'visible' && !ws) {
        console.info('visible  ============');
        connectWebSocket();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [ws]);

  const startNewSession = () => {
    const newSessionId = uuidv4();
    sessionStorage.setItem('session', newSessionId);
    setSessionId(newSessionId);
  };

  useEffect(() => {
    startNewSession();
  }, []);

  const [isDragging, setIsDragging] = useState(false);

  const maxTotalSize = 1 * 1024 * 1024;

  const handleFileChange = (newFiles: FileList | File[]) => {
    if (loading) {
      displayToast('File Upload in Progress','Please wait until the current file upload is completed.','warning',5000);
      return;
    }

    const filesArray = Array.from(newFiles);

    const currentTotalSize = selectedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );
    const newTotalSize = filesArray.reduce(
      (acc, file) => acc + file.size,
      currentTotalSize
    );

    if (newTotalSize > maxTotalSize) {
      displayToast('File Size Limit Exceeded','Total file size exceeds 1 MB. Please select smaller files.','warning',5000);
      return;
    }

    const allowedTypes = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    const validFiles = filesArray.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (validFiles.length !== filesArray.length) {
      displayToast('Invalid File Type','Only PDF, and DOCX are allowed.','warning',5000);
      return;
    }
    if (validFiles.length > 5) {
      displayToast('File Upload Limit Exceeded','You can only upload a maximum of 5 files at a time. Please remove some files and try again.','warning',5000);
      return;
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleRemoveFile = (fileName: string) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (selectedFiles.length >= 5) {
      displayToast('File Upload Limit Exceeded','You can only upload a maximum of 5 files at a time. Please remove some files and try again.','warning',5000);
      return;
    }
    event.preventDefault();
    const files = event.dataTransfer.files;
    setIsDragging(false);
    handleFileChange(files);
  };

  const handleSendFiles = async () => {
    setLoading(true);

    if (selectedFiles.length > 0) {
      setSelectedFiles([]);
      setUploadingFiles(selectedFiles);
      for (let index = 0; index < selectedFiles.length; index++) {
        const element = selectedFiles[index];
        let stopSimulation: (() => void) | null = null;

        try {
          setProgressForFile(index, 0);

          const fileUpload = await handleUpload(element, (actualProgress) => {
            if (stopSimulation) stopSimulation();
            stopSimulation = simulateSlowerProgress(
              actualProgress,
              (progress) => {
                setProgressForFile(index, progress);
              }
            );
          });

          if (fileUpload) {
            if (stopSimulation) stopSimulation();
            setProgressForFile(index, 100);
          } else {
            if (stopSimulation) stopSimulation();
            setProgressForFile(index, 0);
            console.error(`File ${element.name} failed to upload.`);
            break;
          }
        } catch (error) {
          if (stopSimulation) stopSimulation();
          setProgressForFile(index, 0);
          console.error(`Error uploading file ${element.name}:`, error);
          break;
        }

        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }

    setLoading(false);
  };

  const simulateSlowerProgress = (
    actualProgress: number,
    setProgress: (value: number) => void
  ) => {
    let currentProgress = 0;
    const interval = setInterval(() => {
      if (currentProgress < Math.min(actualProgress * 90, 90)) {
        currentProgress += 1;
        setProgress(currentProgress);
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust this value to make the animation faster or slower
    return () => clearInterval(interval);
  };

  const handleUpload = async (
    file: File,
    onProgress: (progress: number) => void
  ) => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const token = await ensureValidToken(localStorage.getItem('token'));

        const response = await axios.post(
          `${process.env.REACT_APP_LLM_API_URL}/upload-user-file-to-azure?&user_id=${profile?.user_id}&session_id=${sessionId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = progressEvent.total
                ? progressEvent.loaded / progressEvent.total
                : 0;
              onProgress(percentCompleted);
            },
          }
        );

        if (response.data.success === true) {
          return true;
        } else {
          displayToast('Upload Failed','Failed to upload the file. Please try again later.','error',3000);
          return false;
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        displayToast('Upload Failed','Failed to upload the file. Please try again later.','error',3000);
        return false;
      }
    } else {
      console.info('No file selected or no partner/persona selected.');
      return false;
    }
  };

  const setProgressForFile = (index: number, value: number) => {
    setProgressArray((prevProgress) => {
      const updatedProgress = [...prevProgress];
      updatedProgress[index] = value;
      return updatedProgress;
    });
  };

  return (
    <>
      {' '}
      <SEOComponent
        title="Nucomply- Marketing Review"
        description="Banking Compliance Expert Marketing Review"
        canonical="/compliance/marketing-review"
      />
      <div style={{ marginTop: '110px' }}>
        <Flex
          w="100%"
          direction="column"
          position="relative"
          mt={{ base: '70px', md: '0px', xl: '0px' }}
        >
          <Flex
            mx="auto"
            w={{ base: '100%', md: '100%', xl: '100%' }}
            maxW="100%"
            justify="center"
            direction={{ base: 'column', md: 'row' }}
          >
            <Card
              border={isDragging && '1px dashed'}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              minW={{ base: '100%', md: '40%', xl: '476px' }}
              maxW={{ base: '100%', md: '40%', xl: '476px' }}
              h="min-content"
              me={{ base: '0px', md: '20px' }}
              mb={{ base: '20px', md: '0px' }}
            >
              <FormLabel
                display="flex"
                ms="10px"
                htmlFor={'body'}
                fontSize="md"
                color={textColor}
                letterSpacing="0px"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                Body <ResetButton handleReset={handleReset} />
              </FormLabel>
              <Flex
                w="100%"
                mb="15px"
                flexDirection="column"
                position="relative"
                border="1px solid"
                borderColor={borderColor}
                borderRadius="10px"
                pt="10px"
                pb="0"
                px="0"
                _focusWithin={{
                  borderColor: 'gray.400',
                  boxShadow: '0 0 0 2px blue.500',
                }}
              >
                {/* Display Selected Files */}
                {!sendButtonClicked && selectedFiles.length > 0 && (
                  <Flex
                    p={{ base: '2px', md: '5px' }}
                    gap="5px"
                    wrap="wrap"
                    ms="2"
                  >
                    {selectedFiles.map((file: any, index: any) => (
                      <Flex
                        key={index}
                        justifyContent="space-between"
                        alignItems="center"
                        mb="4px"
                        width={{ base: '160px', md: '200px' }}
                        p="10px"
                        border="1px solid"
                        borderColor="gray.500"
                        borderRadius="md"
                        position="relative"
                      >
                        <Box display="flex" alignItems="center">
                          {/* Display appropriate icons based on file type */}
                          <Box mr="8px">
                            {file.type === 'application/pdf' && (
                              <Icon as={FaFilePdf} color="white" w={6} h={6} />
                            )}
                            {(file.type === 'application/msword' ||
                              file.type ===
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && (
                              <Icon as={FaFileWord} color="white" w={6} h={6} />
                            )}
                            {file.type === 'text/plain' && (
                              <Icon as={FaFileAlt} color="white" w={6} h={6} />
                            )}
                          </Box>
                          <Box>
                            <Text
                              fontSize="sm"
                              color={inputColor}
                              isTruncated
                              maxW={{ base: '90px', md: '120px' }}
                            >
                              {file.name}
                            </Text>
                            <Text fontSize="sm" color={inputColor}>
                              {/* Display file type in uppercase */}
                              {file.type === 'application/pdf'
                                ? 'PDF'
                                : file.type === 'application/msword'
                                ? 'DOC'
                                : file.type ===
                                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                ? 'DOCX'
                                : file.type === 'text/plain'
                                ? 'TXT'
                                : file.type.toUpperCase()}
                            </Text>
                          </Box>
                        </Box>
                        <IconButton
                          size="xs"
                          aria-label="Remove file"
                          icon={<CloseIcon />}
                          onClick={() => handleRemoveFile(file.name)}
                          position="absolute"
                          top="2px"
                          right="2px"
                        />
                      </Flex>
                    ))}
                  </Flex>
                )}

                <Flex position="relative">
                  <Textarea
                    value={body}
                    border="0px solid"
                    borderTop="none"
                    borderBottomRadius="10px"
                    borderColor={borderColor}
                    p="0px 15px"
                    minH="110px"
                    fontWeight="500"
                    _focus={{ borderColor: 'none' }}
                    color={textColor}
                    placeholder={
                      selectedFiles?.length > 0 ? '' : 'Enter the body here...'
                    }
                    _placeholder={placeholderColor}
                    id="body"
                    onChange={handleBodyChange}
                  />
                </Flex>
              </Flex>
              <FormLabel
                display="flex"
                ms="10px"
                htmlFor="file-upload"
                fontSize="md"
                color={textColor}
                letterSpacing="0px"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                Upload Files
              </FormLabel>
              <Input
                type="file"
                id="file-upload"
                multiple
                onChange={(e) => handleFileChange(e.target.files)}
                border="1px solid"
                borderRadius={'10px'}
                borderColor={borderColor}
                h="60px"
                mb="10px"
                color={textColor}
                display="none" // Hide the input element
              />

              {/* Custom button for triggering the file input */}
              <Flex>
                <Button
                  as="label"
                  htmlFor="file-upload"
                  cursor="pointer"
                  backgroundColor="gray.700"
                  color="white"
                  _hover={{ backgroundColor: 'gray.600' }}
                  mb="20px"
                  borderRadius="10px"
                  p="10px 20px"
                >
                  Select Files
                </Button>
                <Flex mt="10px" ml="5px">
                  {selectedFiles.length > 0 ? (
                    <Text color={textColor} fontSize="sm">
                      {selectedFiles.length} files selected
                    </Text>
                  ) : (
                    <Text color={textColor} fontSize="sm">
                      No file selected
                    </Text>
                  )}
                </Flex>
              </Flex>

              <FormLabel
                display="flex"
                ms="10px"
                htmlFor="complianceConcerns"
                fontSize="md"
                color={textColor}
                letterSpacing="0px"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                Compliance Concerns
              </FormLabel>
              <Flex justifyContent="space-between" mb="10px">
                <Button size="sm" onClick={handleSelectAll}>
                  Select All
                </Button>
                <Button size="sm" onClick={handleRemoveAll}>
                  Remove All
                </Button>
              </Flex>
              <CheckboxGroup
                value={selectedCompliance}
                onChange={handleComplianceChange}
              >
                <Flex wrap="wrap" gap="10px">
                  {complianceList.map((compliance, index) => (
                    <Checkbox
                      key={index}
                      value={compliance.title}
                      colorScheme="purple"
                      fontWeight="500"
                    >
                      {compliance.title}
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>

              <Button
                py="20px"
                px="16px"
                fontSize="md"
                variant="primary"
                borderRadius="45px"
                w={{ base: '100%' }}
                mt="28px"
                h="54px"
                onClick={handleReviewMarketing}
                isLoading={wsLoading ? true : false}
                _hover={{
                  boxShadow:
                    '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                  bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%) !important',
                  _disabled: {
                    bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)',
                  },
                }}
              >
                Review Marketing
              </Button>
            </Card>
            <Card maxW="100%" h="100%">
              <Flex>
                <Text
                  fontSize={'30px'}
                  color={textColor}
                  fontWeight="800"
                  mb="10px"
                >
                  Review
                </Text>
              </Flex>

              <Flex>
                {uploadingFiles.length > 0 && (
                  <Flex w="100%" align="top" my="10px">
                    <Flex flexDirection="column" flex="1">
                      {/* Display Selected Files=================================== */}

                      <Flex
                        p={{ base: '2px', md: '5px' }}
                        gap="5px"
                        wrap="wrap"
                      >
                        {uploadingFiles.map((file: any, index: any) => (
                          <Flex
                            key={index}
                            justifyContent="space-between"
                            alignItems="center"
                            mb="4px"
                            width={{ base: '180px', md: '220px' }}
                            p="10px"
                            border="1px solid"
                            borderColor="gray.500"
                            borderRadius="md"
                            position="relative"
                            flexDirection="column" // Make space for progress bar
                          >
                            <Box display="flex" alignItems="center">
                              <Box mr="8px">
                                {file.type === 'application/pdf' && (
                                  <Icon
                                    as={FaFilePdf}
                                    color="white"
                                    w={6}
                                    h={6}
                                  />
                                )}
                                {(file.type === 'application/msword' ||
                                  file.type ===
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && (
                                  <Icon
                                    as={FaFileWord}
                                    color="white"
                                    w={6}
                                    h={6}
                                  />
                                )}
                                {file.type === 'text/plain' && (
                                  <Icon
                                    as={FaFileAlt}
                                    color="white"
                                    w={6}
                                    h={6}
                                  />
                                )}
                              </Box>
                              <Box>
                                <Text
                                  fontSize="sm"
                                  color={inputColor}
                                  isTruncated
                                  maxW={{ base: '110px', md: '130px' }}
                                >
                                  {file.name}
                                </Text>
                                <Text fontSize="sm" color={inputColor}>
                                  {file.type === 'application/pdf'
                                    ? 'PDF'
                                    : file.type === 'application/msword'
                                    ? 'DOC'
                                    : file.type ===
                                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                    ? 'DOCX'
                                    : file.type === 'text/plain'
                                    ? 'TXT'
                                    : file.type.toUpperCase()}
                                </Text>
                              </Box>
                            </Box>
                            {/* Progress Bar */}
                            <Progress
                              value={progressArray[index]} // Dynamic progress for each file
                              size="sm"
                              colorScheme="white"
                              mt="8px"
                              width="100%"
                              height="4px"
                            />
                          </Flex>
                        ))}
                      </Flex>

                      {/* Display Selected Files=================================== */}
                    </Flex>
                  </Flex>
                )}
              </Flex>

              <Flex ml={6} justifyContent="flex-start">
                {assistantResponse && (
                  <ReactMarkdown className="font-medium markdown-content">
                    {assistantResponse
                      .replace(/```markdown/g, '')
                      .replace(/```/g, '')}
                  </ReactMarkdown>
                )}
              </Flex>

              {loading && (
                <Flex justifyContent="center" alignItems="center">
                  <Spinner size="xl" />
                </Flex>
              )}
              <div>
                <Text color={textColor}>{/* Render API response here */}</Text>
              </div>
            </Card>
          </Flex>
        </Flex>
        {/* Add your PremiumModal component if needed */}
        {/* <PremiumModal /> */}
      </div>
    </>
  );
}
